@import '@/components/variables.scss';

.form {
  
  display: block;
  max-width: calc(100vw - spacer(xl));

  @media screen and (max-width: $x-breakpoint-xs) {
    max-width: 100vw;
  }

  &-light {
    padding: 24px;
    background-color: #F5F5F5;
    border-radius: var(--w_tileBorderRadius);
  }

  input[type=text], input[type=password], input[type=number], input[type=email], input[type=phone], select, textarea, input[type=color] {
  
    display: block;
    appearance: none;
    background-color: var(--w_fieldBg)!important;
    border: var(--w_fieldBorderWidth) solid var(--w_fieldBorderColor);
    color: var(--w_fieldText);
    box-shadow: none;
    outline: none;
    height: $x-field-height;
    border-radius: var(--w_fieldBorderRadius);
    width: 100%;
    padding-left: spacer(lg);
    resize: none;
    
    &:focus {
      border-color: var(--w_fieldOutlineColor) !important;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 16px;
    }

  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    padding-top: spacer(base);
    padding-bottom: spacer(base);
    padding-right: spacer(xl);
    border-radius: var(--w_tileBorderRadius);
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
    
  input[type=color] {
    padding-right: spacer(lg);
  }

  input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 3px;
    background: #DDDDDD;
    border-radius: 3px;
    background-image: linear-gradient(var(--w_primaryColor), var(--w_primaryColor));
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }
  
  [dir="rtl"] input[type="range"] {
    background: var(--w_primaryColor);
    background-image: linear-gradient(#fff, #fff);
    background-size: 30% 100%;
    background-repeat: no-repeat;
  }
  
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--w_primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--w_primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--w_primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background: var(--w_primaryColor);
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    background: var(--w_primaryColor);
  }
  
  input[type="range"]::-ms-thumb:hover {
    background: var(--w_primaryColor);
  }
  
  /* Input Track */
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  select {
    background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23999999%22%20height%3D%22512%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%22512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20clip-rule%3D%22evenodd%22%20d%3D%22m4.93934%2010.9393c.58579-.5857%201.53553-.5857%202.12132%200l8.93934%208.9394%208.9393-8.9394c.5858-.5857%201.5356-.5857%202.1214%200%20.5857.5858.5857%201.5356%200%202.1214l-10%2010c-.5858.5857-1.5356.5857-2.1214%200l-9.99996-10c-.58579-.5858-.58579-1.5356%200-2.1214z%22%20fill%3D%22%23999999%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') !important;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 20px 20px;
  }

  &-grp {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    min-width: 120px;
    align-items: center;
    > div {
      width: 100%;
    }
  }

  &-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    &-check {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center !important;
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 1px solid var(--w_secondaryColor);
      background-color: white;
      color: white;
      overflow: hidden;
      transition: all 0.5s ease;
      svg {
        display: none;
        flex: 0 0 auto;
        width: 14px;
        height: 14px;
      }
      &:after {
        content: "";
        transition: all 0.5s ease;
      }
    }
    input {
      z-index: 1;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    &:has(input:checked) {
      .form-toggle-check {
        background-color: var(--w_secondaryColor);
        color: var(--w_secondaryText);
      }
    }
    &-checked {
      background-color: var(--w_secondaryColor);
      color: var(--w_secondaryText);
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 7px;
        top: 7px;
        background-color: white;
        border-radius: 100%;
        border: 0;
      }
    }
  }
  
  &-thin {
    height: 32px !important;
  }

  &-cells {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: spacer(base);
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      gap: spacer(lg);
    }
    > div {
      flex: 0 0 auto;
    }
  }
  
  &-label, p {
    display: block;
    color: var(--w_primaryColor);
    font-size: .875rem;
    padding-bottom: spacer(xs);
  }
  
  p {
    display: block;
    color: var(--w_primaryColor);
    font-size: .875rem;
    padding-bottom: spacer(xs);
  }
  
  &-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    &50 {
      width: calc(50% - (spacer(base) / 2));
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
    }
    &50a {
      width: calc(50% - (spacer(base) / 2));
      @media screen and (max-width: $x-breakpoint-xs) {
        width: calc(50% - (spacer(lg) / 2));
      }
    }
  }
  
  &-inline {
    > label {
      flex: 0 0 auto;
      padding-right: spacer(base);
    }
    > div {
      flex: 1 1 auto;
    }
  }
  
  &-trn {
    input, select, textarea {
      border: none;
      background-color: transparent;
    }
  }
  
  &-legend {
    display: flex;
    flex-direction: row;
    font-size: .675rem;
    color: black;
    padding-top: spacer(xs);
    > div {
      flex: 0 0 auto;
      &:first-child {
        flex: 1 1 auto;
      }
    }
  }
  
  &-padded {
    padding: spacer(xl) 0;
    position: relative;
  }

  &-error {
    color: var(--w_colorRed) !important;
  }

  &-with-help {
    display: flex;
    width: 100%;
    flex-direction: row;
    border: var(--w_fieldBorderWidth) solid var(--w_fieldBorderColor);
    height: $x-field-height;
    border-radius: var(--w_fieldBorderRadius);
    input, select, textarea {
      flex: 1 1 auto;
      border: none !important;
      height: calc($x-field-height - 2px) !important;
    }
  }

  &-icon {
    flex: 0 0 auto;
    width: 44px;
    height: calc($x-field-height - 2px) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (min-width: $x-breakpoint-xs) {
      display: none;
    }
    input[type=file] {
      display: block;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  
}

.camera {
  display: block;
  z-index: 20000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  .react-html5-camera-photo  {
    display: block;
    width: 100%;
    height: 100%;
    video {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.signature {
  border-bottom: 4px solid #000;
  position: relative;
  height: 108px;
  span {
    display: block;
    font-family: "cc-signature", "Brush Script MT", "Comic Sans MS", "Lucida Handwriting";
    font-size: 3rem;
    padding: spacer(xl);
  }
  img {
    display: block;
    height: 100%;
  }
  > button {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 36px;
    height: 36px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &-canvas {
    display: block;
    width: 100%;
    height: 100%;
  }
}
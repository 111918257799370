@import '@/components/variables.scss';

.header {
  display: flex;
  flex-direction: row;
  gap: spacer(base);
  position: fixed;
  width: 100vw;
  z-index: 200;
  height: $x-header-height;
  align-items: center;
  background-color: var(--w_headerBg);

  > div {
    flex: 0 0 auto;
    &:first-child {
      padding-left: spacer(xl);
    }
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: spacer(base);
    }  
    &:last-of-type {
      padding-right: spacer(xl);
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-right: spacer(base);
      }  
    }
  }

  > span {
    flex: 1 1 auto;
  }

  &-logo {
    img {
      display: block;
      height: 36px;
      width: auto;
    }
  }

  &-button {
    background-color: transparent !important;
    color: white;
    text-decoration: none;
  }

}
@import '@/components/variables.scss';

.cc {

  &-main {
    padding: 0 0 0 0;
    section {
      pre {
        white-space: pre-wrap;
      }
    }
  }

  &-legal {
    padding-top: spacer(xl);
    padding-bottom: spacer(xxl);
  }

  &-hero {
    background-color: var(--primaryColor);
    background: rgb(235,36,88);
    background: linear-gradient(121deg, rgba(235,36,88,1) 0%, rgba(169,55,85,1) 50%, rgba(108,15,40,1) 100%);
    padding-top: spacer(xl);
    padding-left: spacer(xl);
    padding-right: spacer(xl);
    box-shadow: inset 0 -24px 20px -20px rgba(0,0,0,0.2);
    overflow: hidden;
  }

  &-column {
    display: block;
    max-width: 1400px;
    width: 100%;
    margin: auto;
  }

  &-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: spacer(base);
    color: var(--primaryText);
    align-items: center;
    min-height: 64px;
    a {
      text-decoration: none;
      color: var(--primaryText);
      font-weight: 700;
      font-size: 16px;
    }
    > div {
      flex: 0 0 auto;
    }
    > span {
      flex: 1 1 auto;
    }
    &-logo {
      display: block;
      width: auto;
      height: 44px;
      img {
        display: block;
        height: 100%;
      }
    }
  }

  &-highs {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-m {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: spacer(base);
      > div, h1, h2 {
        flex: 0 0 auto;
        font-size: 3rem;
        font-weight: 700;
        color: var(--primaryText);
      }
      > h1 {
        font-size: 3.5rem;
      }
      > h2 {
        font-size: 3.5rem;
      }
    }
    &-i {
      flex: 0 0 auto;
      padding-top: 100px;
      width: 50%;
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 12px 12px 0 0;
        box-shadow: 12px 12px 10px rgba(0,0,0,0.2);
      }
    }
  }

  &-sec {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: spacer(xl);
    &-i {
      flex: 0 0 auto;
      width: calc(50% - spacer(xl));
      padding-top: spacer(xxl);
      padding-bottom: spacer(xxl);
      img {
        display: block;
        height: 240px;
        width: auto;
        margin: auto;
      }
    }
    &-m {
      flex: 1 1 auto;
      padding-top: spacer(xxl);
      padding-bottom: spacer(xxl);
      display: flex;
      flex-direction: column;
      gap: spacer(base);
      span {
        font-size: 1.5rem;
      }
      h2 {
        display: block;
        font-size: 3rem;
        font-weight: 700;
        padding-bottom: spacer(xl);
        color: var(--primaryColor);
      }
    }
  }

  &-button {
    background-color: var(--primaryColor);
    color: var(--primaryText);
    padding-left: spacer(xl);
    padding-right: spacer(xl);
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
    text-decoration: none;
  }

  &-lighter {
    background-color: white;
  }

}
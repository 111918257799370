@import '@/views/agent/variables.scss';

:root {
  --primaryColor: rgb(235, 36, 88);
  --primaryText: #FFFFFF;
  --secondaryColor: rgb(235, 36, 88);
  --secondaryText: #FFFFFF;
  --w_spacer: 12px;
}
* {
  padding: 0;
  margin: 0;
  font-family: var(--w_fontFamily, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif);
  box-sizing: border-box;
}
button {
  appearance: none;
  background-color: transparent;
  background: none;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  border: 0;
}
body {
  display: block;
  background-color: #F5F5F5;
  &:has(.page-print) {
    background-color: white;
  }
  &:has(.l-budco) {
    background-color: white;
  }
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.qr-code {
  position: relative;
  width: 160px !important;
  svg {
    margin: 0 0 0 0 !important;
    width: 160px !important;
    height: 160px !important;
  }
}

.qr-code-sm {
  position: relative;
  width: 64px !important;
  svg {
    margin: 0 0 0 0 !important;
    width: 64px !important;
    height: 64px !important;
  }
}

.disclaimer {
  display: block;
  padding-top: 24px;
  font-size: 10px;
  text-align: justify;
}

.tooltip {
  --b: 1.2em;
  --h: .7em;
  --p: 50%;
  --r: 6px;
  border-radius: var(--r) var(--r) min(var(--r), 100% - var(--p) - var(--b) / 2) min(var(--r), var(--p) - var(--b) / 2) / var(--r);
  background: 0 0 / 100% calc(100% + var(--h)) linear-gradient(100deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9)); /* the gradient */
  position: absolute;
  bottom: calc(100% + var(--h));
  padding: 11px 12px;
  color: white;
  font-size: 12px;
  z-index: 0;
  max-width: 90vw;
  display: none;
}
.tooltip:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0 0 calc(-1*var(--h));
  background-image: inherit;
  clip-path: 
    polygon(50% 50%,
      min(100%, var(--p) + var(--b) / 2) calc(100% - var(--h)),
      var(--p) 100%,
      max(0%, var(--p) - var(--b) / 2) calc(100% - var(--h)));
}

* {
  &:has(> .tooltip) {
    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: var(--bodyText, "#000000");
  cursor: pointer;
}

.link-centered {
  text-decoration: underline;
  color: var(--bodyText, "#000000");
  text-align: center;
  cursor: pointer;
}

.veh-tab {
  border-radius: var(--w_fieldBorderColor);
}

.text-red { color: var(--w_colorRed) }
.text-primary { color: var(--w_primaryColor) }
.text-secondary { color: var(--w_secondaryColor) }
.text-green { color: var(--w_colorGreen) }
.text-blue { color: var(--w_colorBlue) }
.text-bold { font-weight: 700; }
.text-right { text-align: right; }
.text-line-thru { text-decoration: line-through; }
.text-sm { 
  font-size: 12px;
  svg {
    width: 14px;
    height: 14px;
    margin-bottom: -3px;
  }
}

@each $bp in $x-breakpoints {
  .min-b-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-b-#{$bp} {
      display: block !important;
    }
  }
  .min-i-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .min-f-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-f-#{$bp} {
      display: flex !important;
    }
  }
  .max-b-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-b-#{$bp} {
      display: block !important;
    }
  }
  .max-i-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .max-f-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-f-#{$bp} {
      display: flex !important;
    }
  }
}

@import '@/components/variables.scss';

.page {
  display: block;
}

.page-full {
  display: block;
  width: 100vw;
  height: 100vh;
  position: relative;
  &-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.web-main {
  padding-top: calc($x-header-height);
  padding-bottom: spacer(xl);
}
.web-main {
  padding-left: spacer(xl);
  padding-right: spacer(xl);
  &-iframed {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.legal {
  padding-top: spacer(xl);
  padding-left: spacer(xl);
  padding-right: spacer(xl);
  padding-bottom: spacer(xl);
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-left: spacer(base);
    padding-right: spacer(base);
  }
  h1 {
    padding-bottom: spacer(xl);
  }
}
section {

  display: block;
  margin-bottom: spacer(xl);
  position: relative;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: $x-breakpoint-xs) {
    margin-bottom: spacer(base);
  }

  > section {
    margin-top: 0;
  }

  &.top {
    padding-top: spacer(xxl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-top: spacer(xl);
    }
  }

  &.max-b-xs, &.max-f-xs {
    @media screen and (min-width: $x-breakpoint-xs) {
      margin-bottom: 0;
    }
  }
  &.min-b-xs, &.min-f-xs {
    @media screen and (max-width: $x-breakpoint-xs) {
      margin-bottom: 0;
    }
  }

  &.centered {
    text-align: center;
  }

  &.m-full {
    @media screen and (max-width: $x-breakpoint-xs) {
      padding: 0 0 0 0;
      text-align: left;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.bg {
    background-color: white;
    border-radius: var(--w_tileBorderRadius);
    padding: spacer(xl) spacer(xl);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  }

  &.bg-primary {
    background-color: var(--w_primaryColor);
    color: var(--w_primaryText);
    border-radius: var(--w_tileBorderRadius);
    padding: spacer(xl) spacer(xl);
  }

  &.embed {
    margin-top: spacer(base);
    margin-left: spacer(base);
    margin-right: spacer(base);
    margin-bottom: spacer(base);
    width: calc(100% - 2 * spacer(base));
  }

  &.extra-pad {
    margin-bottom: spacer(xxl);
  }

  &.pad-d {
    padding: 0 spacer(xl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding: 0 0 0 0;
    }
  }

  &.big-pad {
    padding: spacer(xxl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding: spacer(xl);
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

}

h1 {
  font-size: 2.5rem;
  display: block;
  white-space: wrap;
}

h2 {
  font-size: 1.5rem;
  display: block;
  white-space: wrap;
}

.form-column {
  display: block;
  margin: auto;
  width: 640px;
  max-width: 100vw;
}

.fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.flex, .flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex-row-column {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
  }
}
.flex-gapped {
  gap: spacer(base);
}
.flex-gapped-xl {
  gap: spacer(xl);
}
.flex-gapped-sm {
  gap: spacer(sm);
}
.flex-col {
  flex: 0 0 auto;
}
.flex-stretch {
  flex: 1 1 auto;
}
.flex-align-middle {
  align-items: center;
}
.flex-align-center {
  justify-content: center;
}
.flex-col-50 {
  width: 50%;
}
.flex-gapped .flex-col-50 {
  width: calc(50% - (spacer(base) / 2));
}
.flex-gapped-xl .flex-col-50 {
  width: calc(50% - (spacer(xl) / 2));
}
.flex-gapped-xl .flex-col-50 {
  width: calc(50% - (spacer(sm) / 2));
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

.l {
  &-container {
    display: flex;
    flex-direction: column;
    gap: spacer(xl);
    width: 100%;
    padding-top: spacer(xl);
    padding-bottom: spacer(xl);
    position: relative;
  }
  &-row {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
  }
  &-main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    gap: spacer(xl);
    width: 100%;
    position: relative;
  }
  &-sections {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  &-rows {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: spacer(xl);
    width: 100%;
    position: relative;
  }
  &-chat {
    flex: 0 0 auto;
    width: $x-chat-max-width;
    padding-left: spacer(xl);
    z-index: 201;
    position: relative;
    @media screen and (max-width: $x-breakpoint-lg) {
      position: fixed;
    }
    &-hidden {
      width: 0;
      padding-left: 0;
    }
  }
  &-checkout {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    width: 1200px;
    max-width: 100%;
    margin: auto;
    gap: spacer(xl);
    position: relative;
    @media screen and (max-width: $x-breakpoint-lg) {
      width: 100%;
      flex-wrap: wrap;
    }
  }
  &-left {
    flex: 0 0 auto;
    width: 440px;
    position: relative;
    @media screen and (max-width: $x-breakpoint-lg) {
      width: 100%;
    }
  }
  &-middle {
    flex: 1 1 auto;
    position: relative;
  }
  &-doc {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    width: 1200px;
    max-width: 100%;
    margin: auto;
    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-budco {
    // padding-top: spacer(base);
    background-color: white;
    width: 100%;
    height: calc(100vh - $x-header-height);
    iframe {
      height: 100%;
      max-width: 100%;
      margin: auto;
      // @media screen and (max-width: $x-breakpoint-xs) {
      //   height: 4000px;
      // }
    }
  }
  &-chat-open {
    .l-checkout {
      @media screen and (max-width: 1800px) {
        width: calc(100vw - 500px - (2*spacer(xl)));
      }
      @media screen and (max-width: 1600px) {
        .form-cell {
          width: 100%;
        }
      }
      @media screen and (max-width: 1400px) {
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        .l-left {
          width: 100%;
        }
      }
    }
    .l-doc {
      @media screen and (max-width: 1800px) {
        width: calc(100vw - 500px - (2*spacer(xl)));
      }
      @media screen and (max-width: 1400px) {
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        .l-left {
          width: 100%;
        }
      }
    }
  }
  &-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacer(xl);
    position: fixed;
    top: $x-header-height;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $x-light-bg-color;
    z-index: 500;
  }
}

.page-center {
  display: block;
  margin: auto;
}

.page-error {
  display: block;
  padding: spacer(xl);
  background-color: var(--w_redColor, #BB0000);
  color: white;
  border-radius: var(--w_tileBorderRadius);
  * {
    color: white;
  }
}

/* Customization */

/* Print */
.page-print {
  main {
    padding-top: 0;
    padding-bottom: 0;
  }
  section {
    border-radius: 0;
    margin-bottom: spacer(base);
    &:last-child {
      margin-bottom: 0;
    }
  }
  * {
    color: black !important;
  }
  .bg {
    box-shadow: none;
  }
}

.page-embed {
  main {
    padding-top: 0;
    padding-bottom: 0;
  }
  section {
    border-radius: 0;
    margin-bottom: spacer(base);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bg {
    box-shadow: none;
  }
}

.landing {
  h1 {
    display: block;
    font-weight: 400;
    font-size: 2rem;
    padding-bottom: spacer(base);
  }
  h2 {
    display: block;
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: spacer(xl);
  }
  &-hero {
    display: block;
    height: 720px;
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      height: calc(100vw * .75);
    }
    &-img {
      height: auto !important;
    }
    iframe {
      display: block;
      bordeR: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-bullets {
    padding: 0 0 0 spacer(xl);
    margin: 0;
    li {
      padding-left: spacer(xl);
      padding-bottom: 8px;
    }
  }
}
.login-panel {
  width: 320px;
  max-width: calc(100vw - 48px);
}
.video-fs {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  background-color: black !important;
  border-radius: 0 !important;
  overflow: hidden;
  z-index: 2000000;
  &-container {
    display: block;
    position: absolute;
    top: 44px;
    bottom: 44px;
    left: 0;
    right: 0;
  }
  &-x {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    color: white;
    svg {
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
    }
  }
}

.powered-by {
  display: block;
  text-align: center;
  font-size: 12px;
  a {
    color: var(--w_primaryColor);
  }
}

.find-vin {
  &-popup {
    // width: calc(90vh - 64px) !important;
    // height: 90vh !important;
    // max-width: calc((640px * 4/3)) !important;
    // max-height: 640px !important;
    // @media screen and (max-width: $x-breakpoint-xs) {
    //   max-width: 100vw !important;
    //   max-height: 100vh !important;
    //   width: calc(100vw - 64px) !important;
    //   height: calc(100vw + 64px) !important;
    // }
  }
  &-container {
    display: block;
    width: 800px;
    height: 600px;
    max-width: calc(((90vh - 64px) * 4/3)) !important;
    max-height: calc(90vh - 64px) !important;
    position: relative;
    background: #999999;
    background: linear-gradient(121deg, rgba(119,119,119,1) 0%, rgba(153,153,153,1) 50%, rgba(153,153,153,1) 100%);
    padding: 0 !important;
    @media screen and (max-width: $x-breakpoint-xs) {
      max-width: 100vw !important;
      max-height: 100vh !important;
      width: 100vw !important;
      height: calc(100vw * 3/4) !important;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  gap: spacer(base);
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
    text-align: center;
  }
  &-block {
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    @media screen and (max-width: $x-breakpoint-xs) {
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
    }
  }
  &-left {
    flex: 1 1 auto;
  }
  &-right {
    flex: 0 0 auto;
  }
  a, * {
    font-size: 12px;
    text-decoration: none;
    color: #000000;
  }
}

.popup {
  &-vehicle {
    width: 540px;
  }
  &-contact {
    width: 540px;
  }
  &-signature {
    width: 640px;
  }
}
.gallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.95);
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease;
  &-inner {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    img {
      flex: 0 0 auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &-thumbs {
    flex: 0 0 auto;
    padding: spacer(base);
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 8px;
    &-in {
      display: block;
      margin: auto;
      height: 48px;
      a {
        height: 48px;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;
        }
        img {
          display: block;
          height: 100%;
          width: auto;
        }
      }
    }
  }
  &-show {
    opacity: 1;
    z-index: 2000;
  }
  &-close {
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    color: white;
    position: absolute;
    right: 12px;
    top: 12px;
    svg {
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
    }
  }
}
@import '@/components/variables.scss';

.site-layout {
  display: flex;
  flex-direction: column;
  gap: spacer(xl);
  width: 100%;
  max-width: 1024px;
  margin: auto;
  &-full {
    max-width: unset;
  }
}
.site-form {
  display: flex;
  flex-direction: row;
  gap: spacer(xl);
  width: 100%;
}
.site-col {
  flex: 0 0 auto;
  width: calc(50% - spacer(base));
  display: flex;
  flex-direction: column;
  gap: spacer(xl);
  img {
    display: block;
    width: 100%;
    max-width: 240px;
    margin: auto;
  }
}
.site-stand {
  display: flex;
  flex-direction: column;
  gap: spacer(xl);
  background-color: white;
  print-color-adjust: exact;
  padding: spacer(xl);
  text-align: center;
  h2 {
    display: block;
    font-size: 2rem;
    text-align: center;
  }
  h3 {
    display: block;    
    font-size: 1.2rem;
    text-align: center;
  }
  &-sizes {
    display: flex;
    flex-direction: row;
    gap: spacer(xl);
    > a {
      flex: 0 0 auto;
      width: 72px;
      height: 96px;
      border: 1px solid #999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-selected {
      background-color: #000000;
      color: #FFFFFF;
    }
  }
  &-size {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media print {
      print-color-adjust: exact;
    }
    > div {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: spacer(xl);
      print-color-adjust: exact;
    }
    img {
      display: block;
      margin: auto;
      width: 50%;
      max-width: 50%;
    }
    &-810 {
      width: calc(8*96px);
      height: calc(10*96px);
      img {
        width: 65%;
        max-width: 65%;
      }
      h2 {
        font-size: 3rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
    &-57 {
      width: calc(5*96px);
      height: calc(7*96px);
    }
    &-46 {
      width: calc(4*96px);
      height: calc(6*96px);
    }
  }
}
.site-grid {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $x-header-height - spacer(xl) - spacer(xl) - spacer(xl) - 44px);
  background-color: white;
  margin-bottom: -spacer(xl);
  &-full {
    align-items: center;
    justify-content: center;
  }
  &-button {
    flex: 0 0 auto;
    position: relative;
    input[type=file] {
      opacity: 0;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-table {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    min-width: 100%;
  }
  h3 {
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 14px;
  }
  &-header {
    flex: 0 0 auto;
    border-bottom: 2px solid #DDD;
  }  
  &-body {
    flex: 1 1 auto;
    overflow-y: auto;
    width: 100%;
  }
  &-row {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: spacer(base);
  }
  &-cell {
    flex: 0 0 auto;
    width: 12%;
    padding-top: spacer(sm);
    padding-bottom: spacer(sm);
    &:first-child {
      padding-left: spacer(base);
    }
  }
  &-url {
    width: 30%;
  }
  &-footer {
    flex: 0 0 auto;
    border-top: 2px solid #DDD;
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    align-items: center;
    > div {
      flex: 0 0 auto;
      width: unset;
      &:last-of-type {
        padding-right: spacer(base);
      }
    }
    > span {
      flex: 1 1 auto;
    }
  }
}
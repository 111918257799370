$x-header-height: 64px;
$x-chat-max-width: 500px;

$x-breakpoint-xxl: 1400px;
$x-breakpoint-xl: 1296px;
$x-breakpoint-lg: 1024px;
$x-breakpoint-sm: 768px;
$x-breakpoint-xs: 500px;

$x-base-spacer: var(--w_spacer);

$x-field-height: 44px;

$x-breakpoints: xl, lg, sm, xs;

$x-light-bg-color: #F5F5F5;
$x-border-color: #DDDDDD;

$x-color-red: var(--w_colorRed);
$x-color-yellow: var(--w_colorYellow);
$x-color-green: var(--w_colorGreen);
$x-color-dark-green: var(--w_colorDarkGreen);
$x-color-dark-blue: var(--w_colorDarkBlue);
$x-color-gray: #999999;

$x-collapsed-width: 54px;

$x-spacer-values: (
  base: 1,
  xs: .5,
  sm: .75,
  md: 1.25,
  lg: 1.5,
  xl: 2,
  xxl: 4
);

@function breakpoint($pt) {
  @if $pt == 'xs' {
    @return $x-breakpoint-xs;
  }
  @if $pt == 'sm' {
    @return $x-breakpoint-sm;
  }
  @if $pt == 'lg' {
    @return $x-breakpoint-lg;
  }
  @if $pt == 'xl' {
    @return $x-breakpoint-xl;
  }
  @if $pt == 'xxl' {
    @return $x-breakpoint-xxl;
  }
};

@function spacer($s) {
  @return calc($x-base-spacer * map-get($x-spacer-values,  #{$s}));
};

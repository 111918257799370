@import '@/components/variables.scss';

.product {

  &-bundle-steps {
    display: inline-block;
    vertical-align: top;
    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: spacer(xl);
      flex-wrap: wrap;
      @media screen and (max-width: $x-breakpoint-xs) {
        gap: spacer(base);
      }
      > div, button {
        flex: 0 0 auto;
        color: var(--w_primaryColor);
        font-weight: 700;
        font-size: 16px;
        svg {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-bottom: -4px;
        }
      }
    }
  }
  
  &-bundle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    border-radius: var(--w_tileBorderRadius);
    background-color: var(--w_secondaryColor);

    > div {
      flex: 0 0 auto;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--w_secondaryText);
      @media screen and (max-width: $x-breakpoint-xs) {
        padding: 0 spacer(base) 0 spacer(base);
        height: 64px;
        width: 100%;
      }
      &:first-child {
        border-radius: var(--w_tileBorderRadius) 0 0 var(--w_tileBorderRadius);
        padding: 0 0 0 spacer(xl);
        @media screen and (max-width: $x-breakpoint-xs) {
          padding: 0 0 0 spacer(base);
        }
        svg {
          flex: 0 0 auto;
          width: 32px;
          height: 32px;
        }
      }
      &:last-child {
        border-radius: 0 var(--w_tileBorderRadius) var(--w_tileBorderRadius) 0;
        padding: 0 0 0 0;
      }
      h2 {
        flex: 0 0 auto;
        font-size: 1.5rem;
        @media screen and (max-width: $x-breakpoint-xs) {
          font-size: 1rem;
        }
      }
      span {
        font-size: 12px;
      }
    }
    &-dark {
      flex: 0 0 auto !important;
      display: flex !important;
      flex-direction: row !important;
      background-color: rgba(0,0,0,0.1) !important;
      width: auto !important;
      align-items: center;
      gap: spacer(base);
      padding-right: spacer(xl) !important;
      padding-left: spacer(xl) !important;
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-right: spacer(base) !important;
        padding-left: spacer(base) !important;
        }
    }
    &-name {
      flex: 1 1 auto !important;
    }
    &-icon {
      padding-right: spacer(base) !important;
    }
    &-info {
      flex: 0 0 auto;
      button {
        height: 100%;
        color: var(--w_secondaryText);
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &-items {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: spacer(xl);
    flex-wrap: wrap;

    > div {
      flex: 0 0 auto;
      width: 320px;
      max-width: calc(100% - (spacer(xl) * 2));
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      align-items: flex-start;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }

      > * {
        flex: 0 0 auto;
        width: calc(320px - spacer(base));
        @media screen and (max-width: $x-breakpoint-xs) {
          width: 100%;
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-top: 4px;
        color: var(--w_secondaryColor);
      }
      i {
        &:has(svg) {
          width: 24px;
          height: 24px;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
      h3 {
        display: block;
        font-weight: 700;
        font-size: 1rem;
      }
      span {
        font-size: 14px;
        white-space: normal;
      }
      h5 {
        font-size: 14px;
        white-space: normal;
        padding: 0 0 0 0;
        text-decoration: line-through;
        color: #AA0000;
        font-weight: 400;
        display: inline-block;
      }
    }

    &-comment {
      svg {
        color: black !important;
      }
      &-by i {
        display: block;
        padding-top: 4px;
        font-size: 12px !important;
      }
    }

    &-video {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 160px;
      position: relative;
      overflow: hidden;
      border-radius: var(--w_tileBorderRadius);
      &-play {
        flex: 0 0 auto;
        color: var(--w_secondaryColor);
        z-index: 2;
        svg {
          display: block;
          width: 44px !important;
          height: 44px !important;
          background-color: white;
          border-radius: 100%;
        }
      }
    }

    &-actions {
      flex: 0 0 auto;
      padding-top: spacer(sm);
      display: flex;
      flex-direction: row;
      gap: spacer(xs);
      a, button {
        background-color: #DDDDDD;
        height: 32px;
        border-radius: var(--w_buttonBorderRadius);
        padding: 0 spacer(base);
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        font-size: 14px;
        cursor: pointer;
        &:has(svg) {
          width: 32px;
          padding: 0 0 0 0;
        }
        svg {
          color: black !important;
          margin-top: 0;
        }
      }
      &-plain {
        background-color: transparent !important;
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    &-removed {
      opacity: 0.3
    }
  }

  &-tiles {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: spacer(xl);
    flex-wrap: wrap;
    @media screen and (max-width: $x-breakpoint-xs) {
      gap: spacer(base);
    }
  }

  &-tile {
    
    flex: 0 0 auto;
    width: calc(50% - (spacer(xl)/2));
    height: 400px;
    border-radius: var(--w_tileBorderRadius);
    background-color: white;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

    &-1 {
      width: 100%;
    }

    @media screen and (max-width: $x-breakpoint-xl) {
      width: 100%;
      height: auto;
      min-height: 200px;
    }

    display: flex;
    flex-direction: column;

    &-top {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      padding: spacer(xl);
      color: #FFFFFF;
      position: relative;
      > div {
        flex: 0 0 auto;
        z-index: 2;
        &:first-of-type {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        svg {
          width: 44px;
          height: 44px;
        }
        span {
          display: block;
        }
      }
      &-bgi {
        width: 100%;
        height: 100%;
      }
      &-p {
        flex: 1 1 auto !important;
        text-align: right;
      }
      &-price {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 700;
      }
      &-discount {
        display: inline-block !important;
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 700;
        color: var(--w_colorRed);
        text-decoration: line-through;
      }
      &-subtext {
        font-size: 12px;
      }
      &-deleted {
        opacity: 0.3;
        pointer-events: none;
        background-color: #333333 !important;
      }
    }

    &-info {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: spacer(base);
      overflow-y: auto;
      padding-bottom: spacer(xl);
      &-name {
        flex: 0 0 auto;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 0 spacer(xl);
      }
      &-terms {
        padding: 0 spacer(xl);
      }
      ul {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        gap: spacer(base);
        margin: 0 0 0 0;
        list-style: none;
        padding: 0 spacer(xl);
        li {
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          gap: spacer(base);
          > div {
            flex: 0 0 auto;
            &:last-child {
              flex: 1 1 auto;
            }
          }
          svg {
            color: #0f9042;
            width: 16px;
            height: 16px;
            margin-top: 2px;
          }
        }
      }
    }

    &-bottom {
      flex: 0 0 auto;
      border-top: 1px solid #EEEEEE;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: calc(spacer(base) + spacer(base)/2);
      &-price {
        flex: 1 1 auto;
        padding: spacer(base) spacer(base) spacer(base) spacer(xl);
        > span {
          display: block;
          &:first-child {
            font-weight: 700;
            font-size: 1.2rem;
          }
          &:last-child {
            font-size: 12px;
          }
        }
      }
      &-discount {
        color: var(--w_colorRed);
        font-size: 14px;
        text-decoration: line-through;
      }
      &-button {
        flex: 0 0 auto;
        width: 36px;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #777777;
        transition: all 0.5s ease;
        border-radius: var(--w_buttonBorderRadius);
        &:hover {
          background-color: var(--w_primaryColor);
          color: white;
        }
        &-red {
          background-color: white;
          color: var(--w_colorRed);
          &:hover {
            background-color: var(--w_colorRed);
            color: white;
          }
        }
        &-green {
          background-color: white;
          color: var(--w_colorGreen);
          &:hover {
            background-color: var(--w_colorGreen);
            color: white;
          }
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-deleted {
      opacity: 0.3;
      pointer-events: none;
      .product-tile-top {
        background-color: #999999 !important;
      }
    }

  }

  &-popup {
    width: 640px;
    height: 100vh;
  }

  &-customize {
    display: block;
    padding: spacer(xxl);
  }

  &-option-filters {
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    flex-wrap: wrap;
    > div {
      flex: 0 0 auto;
      width: calc((100% - (spacer(base)*2))/3);
      display: flex;
      flex-direction: column;
      gap: 4px;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
    }
    label {
      flex: 0 0 auto;
      font-size: 12px;
      color: #777777;
    }
    select {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  &-options {
    display: flex;
    flex-direction: column;
    gap: spacer(base);
  }

  &-option {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: #F5F5F5;
    border-radius: var(--w_tileBorderRadius);
    width: 100%;
    padding-bottom: spacer(base);
    &-selected {
      box-shadow: 0 0 2px var(--w_primaryColor);
    }
  }
  &-installments {
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    margin-bottom: spacer(base);
  }
  &-info {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: spacer(base);
    align-items: center;
    padding-left: spacer(base);
    padding-right: spacer(base);
    padding-top: spacer(base);

    h3 {
      flex: 0 0 auto;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      img {
        flex: 0 0 auto;
        height: 20px;
      }
    }
    h2 {
      flex: 1 1 auto;
      font-size: 1.2rem;
      font-weight: 700;
    }
    &-stretch {
      flex: 1 1 auto !important;
    }
    > div {
      flex: 0 0 auto;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > div {
        display: block;
        font-size: 12px;
      }
    }
    &-stretch {
      flex: 1 1 auto;
    }
    &-exp {
      > button, span {
        height: 20px;
        width: 32px;
        background-color: transparent;
        color: var(--w_secondaryColor);
        transform: rotate(-90deg);
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    &-lines {
      flex: 0 0 auto;
      display: flex !important;
      flex-direction: column !important;
      gap: 4px !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      text-align: left !important;
    }
    &-desc {
      flex: 0 0 auto;
      color: #999999;
      font-size: 12px;
    }
  }
  &-price {
    font-weight: 700;
    color: var(--w_secondaryColor);
  }

  &-plan-terms {
    flex: 1 1 auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: spacer(base);
    > label, a {
      flex: 0 0 auto;
      width: calc(50% - (spacer(base)/2));
      background-color: white;
      border-radius: var(--w_fieldBorderRadius);
      position: relative;
      padding: 12px 12px;
      text-align: left;
      justify-content: flex-start;
      gap: 12px;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
      > span {
        flex: 1 1 auto;
      }
    }
    > div {
      flex: 0 0 auto;
      width: calc(50% - (spacer(base)/2));
    }
  }

  &-desc {
    display: flex;
    flex-direction: row;
    padding-top: spacer(base);
    gap: spacer(base);
    width: 100%;
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      padding-top: 0;
      padding-left: spacer(base);
      padding-right: spacer(base);
    }
    &-info {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: spacer(base);
      max-width: 480px;
    }
    &-bullets {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: spacer(xs);
      > div {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        gap: spacer(xs);
        > div {
          font-size: 14px;
        }
        svg {
          height: 16px !important;
          width: 16px !important;
        }
      }
    }
    &-req {
      display: block;
      padding-top: spacer(xl);
      padding-left: spacer(base);
      padding-right: spacer(base);
      width: 100%;
    }
    &-brochure {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      * {
        flex: 0 0 auto;
      }
    }
    &-video {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40%;
      position: relative;
      min-width: 244px;
      min-height: 140px;
      border-radius: var(--w_tileBorderRadius);
      overflow: auto;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
    }
  }

  &-config {
    flex: 0 0 auto;
    display: flex !important;
    flex-direction: row;
    gap: spacer(xl);
    width: 100%;
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
    padding-left: spacer(base);
    padding-right: spacer(base);
    &-show {
      max-height: 999999px;
      margin-top: spacer(base);
    }
  }
  &-terms {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: spacer(xs);
    width: 100%;
    &-row {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: calc(spacer(base) - 4px);
    }
    &-desc {
      padding: 0 spacer(base) spacer(base);
    }
    &-options {
      padding: 0 spacer(base) spacer(base);
      display: flex;
      flex-direction: row;
      > div {
        flex: 0 0 auto;
        &:first-child {
          padding-left: calc(16px + spacer(base));
        }
      }
      select {
        height: 32px;
        width: auto;
        min-width: 240px;
        font-size: 14px;
        @media screen and (max-width: $x-breakpoint-xs) {
          font-size: 1rem;
        }
      }
    }
    &-item {
      flex: 0 0 auto;
      padding: spacer(sm) spacer(base) spacer(sm)  spacer(base);
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      align-items: center;
      width: 100%;
      &-more {
        flex: 0 0 auto;
      }
      &-text {
        flex: 1 1 auto;
        font-size: 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        > div {
          font-size: 14px;
        }
      }
      &-price {
        flex: 0 0 auto;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        > div {
          font-size: 14px;
          text-align: right;
          a {
            font-size: 14px;
            text-decoration: underline;
          }
        }
        .button {
          height: 32px;
          padding-left: spacer(base);
          padding-right: spacer(base);
        }
      }
    }
    > div {
      flex: 0 0 auto;
      font-size: 12px;
    }
    &-items {
      display: flex;
      flex-direction: row;
      gap: spacer(xs);
      flex-wrap: wrap;
      button {
        flex: 0 0 auto;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        font-size: 12px;
        padding: spacer(xs);
      }
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

}

.loading-bg {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #fff;
  background: linear-gradient(to right, 
    rgba(0,0,0,.20)   0%, 
    rgba(0,0,0,.10)  30%, 
    rgba(0,0,0,.05)  50%, 
    rgba(0,0,0,.10)  70%, 
    rgba(0,0,0,.20) 100%
  );
  -webkit-backface-visibility: hidden;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

.product-column {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 1000px;
  position: relative;
}

// .product-tile {
  
//   display: flex;
//   width: 100%;
//   flex-direction: row;
//   align-items: center;
//   border: 1px solid #EEEEEE;
//   box-shadow: 0 0 20px rgba(0,0,0,0.1);
//   border-radius: spacer(base);

//   &-cart {
//     align-items: flex-start;
//   }

//   > div {
//     padding: spacer(xl) spacer(xl) spacer(xl) 0;
//     &:first-child {
//       padding-left: spacer(xl);
//     }
//   }

//   &-main {
//     flex: 1 1 auto;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     gap: spacer(base);
//   }

//   &-name {
//     flex: 0 0 auto;
//     font-size: 1.5rem;
//     font-weight: 700;
//   }

//   &-desc {
//     flex: 0 0 auto;
//     width: 100%;
//     white-space: wrap;
//     font-size: 1rem;
//   }

//   &-items {
//     flex: 0 0 auto;
//     display: flex;
//     flex-direction: row;
//     gap: spacer(xl);
//     > div {
//       flex: 0 0 auto;
//     }
//     button {
//       background-color: transparent !important;
//       color: black !important;
//       height: auto !important;
//       padding: 0 0 0 0 !important;
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       font-weight: 400;
//       i {
//         height: 24px;
//       }
//       svg {
//         width: 24px;
//         height: 24px;
//       }
//     }
//   }

//   &-term {
//     color: var(--w_colorBlue);
//     font-weight: 700;
//   }

//   &-price {
//     display: block;
//     border-radius: spacer(base);
//     padding: spacer(base) spacer(base);
//     background-color: var(--w_primaryColor);
//     color:  var(--w_primaryText);
//     font-size: 2rem;
//     width: 160px;
//     font-weight: 700;
//     text-align: center;
//     span {
//       display: block;
//       padding-top: spacer(xs);
//       padding-bottom: spacer(xs);
//       font-size: 1rem;
//       font-weight: 400;
//     }
//   }

// }

.checkout {

  &-products {
    display: flex;
    flex-direction: column;
    gap: spacer(xl);

    > div {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      width: 100%;
      @media screen and (max-width: $x-breakpoint-xs) {
        // flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      > div {
        flex: 0 0 auto;
        flex-wrap: wrap;
        &:last-child {
          text-align: right;
          @media screen and (max-width: $x-breakpoint-xs) {
            text-align: left;
          }
        }
        &:first-child {
          flex: 1 1 auto;
          text-align: left;
        }
      }
    }
    &-resp {
      flex-direction: row !important;
      @media screen and (max-width: $x-breakpoint-xs) {
        flex-direction: column !important;
      }
    }
    &-name1 {
      display: block;
      padding-bottom: 4px;
      color: #999999;
      font-size: 14px;
    }
    &-name2 {
      display: block;
      padding-bottom: 4px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &-gap {
      padding-bottom: spacer(xl);
    }
    &-m-gap {
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-bottom: 0;
        display: none;
      }
    }
    &-details {
      display: block;
      font-size: 14px;
    }
    &-summary {
      display: block;
      padding-bottom: 8px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &-logo {
      display: block;
      padding-top: 4px;
      img {
        display: block;
        height: 32px;
        width: auto;
      }
    }
    &-price-d {
      font-weight: 700;
      @media screen and (max-width: $x-breakpoint-xs) {
        display: none;
      }
    }
    &-price-m {
      font-weight: 700;
      padding-top: 8px;
      @media screen and (min-width: $x-breakpoint-xs) {
        display: none;
      }
    }

    &-divider {
      flex: 0 0 auto;
      border: 1px solid #DDDDDD;
    }

    &-remove {
      display: block;
      padding-top: 4px;
      color: darkred;
      cursor: pointer;
    }

    &-pp {
      display: inline-block;
      margin-top: spacer(base);
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: calc(spacer(base)/2);
        flex-wrap: wrap;
        width: 100%;
        > span, a {
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: spacer(sm);
          text-decoration: none;
          border-radius: var(--w_tileBorderRadius);
          padding: 0 spacer(base);
          background-color: #EEEEEE;
          height: 44px;
          color: #000000;
        }
        a {
          &:hover {
            background-color: var(--w_primaryColor);
            color: var(--w_primaryText);
          }
        }
      }
      a {
        svg {
          height: 20px;
        }
      }
    }

  }

  // display: block;
  // margin: auto;
  // width: 1400px;
  // max-width: 100vw;
  // position: relative;
  // padding-top: spacer(xl);

  // &-products {
  //   flex: 0 0 auto;
  //   width: 35%;
  //   display: flex;
  //   flex-direction: column;
  //   gap: spacer(base);
  //   border: 1px solid #EEEEEE;
  //   box-shadow: 0 0 20px rgba(0,0,0,0.1);
  //   border-radius: spacer(base);
  //   padding: spacer(xl);
  //   @media screen and (max-width: $x-breakpoint-xs) {
  //     width: 100%;
  //     margin-left: spacer(xl);
  //     margin-right: spacer(xl);
  //     width: calc(100% - 2 * spacer(xl));
  //   }
  // }

  // &-details {
  //   flex: 1 1 auto;
  // }

  // &-product {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   padding-bottom: 0;
  //   &-main {
  //     flex: 1 1 auto;
  //     display: flex;
  //     flex-direction: column;
  //     gap: spacer(base);
  //   }
  //   &-items {
  //     display: flex;
  //     flex-direction: row;
  //     gap: spacer(base);
  //   }
  // }

}

.checkout-layout {
  display: flex;
  flex-direction: row;
  gap: spacer(xl);

  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
  }
}

.confirmation {
  display: block;
  border-radius: var(--w_tileBorderRadius);
  background-color: white;
  padding: spacer(xl);
  background-color: #EEEEEE;
}

.divider {
  display: block;
  border-top: 1px solid #000000;
}

/* Print */
.page-print {
  .checkout-products {
    gap: spacer(base);
  }
  .checkout-products-logo {
    img {
      height: 24px;
    }
  }
}

.vehicle {
  display: flex;
  flex-direction: row;
  gap: spacer(xl);
  align-items: center;
  &-image {
    flex: 0 0 auto;
    height: auto;
    width: auto;
    // height: 24px;
    // width: 104px;
    position: relative;
    &-float {
      display: block;
      position: absolute;
      right: 0;
      top: -32px;
      height: 124px;
      width:166px;
    }
    &-logo {
      display: block;
      height: 64px;
      width:64px;
    }
  }
  &-trim {
    flex: 1 1 auto;
    &-name {
      display: block;
      padding-bottom: 4px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &-miles {
      @media screen and (min-width: $x-breakpoint-xs) {
        display: none;
      }
    }
  }
  &-miles {
    flex: 0 0 auto;
    text-align: right;
    @media screen and (max-width: $x-breakpoint-xs) {
      display: none;
    }
  }
}

.pmt {
  display: block;
  padding: spacer(base) spacer(base) 0 spacer(base);
  width: 100%;
  transition: all 0.5s ease;
  &-goto {
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    padding: spacer(xl);
    border-radius: var(--w_tileBorderRadius);
    > div {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      align-items: center;
      > div {
        flex: 0 0 auto;
      }
      svg {
        width: 32px;
        height: 32px;
      }
    }
    &-m {
      flex: 0 0 auto !important;
      max-width: 320px;
      @media screen and (max-width: $x-breakpoint-xs) {
        flex: 1 1 auto !important;
        max-width: 100vw;
      }
    }
  }
  &-hidden {
    padding: 0 0 0 0;
    height: 0;
    overflow: hidden;
  }
}

apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
      display: inline-block;
      -webkit-appearance: -apple-pay-button;
      -apple-pay-button-type: buy;
  }
  .apple-pay-button-with-text > * {
      display: none;
  }
  .apple-pay-button-black-with-text {
      -apple-pay-button-style: black;
  }
  .apple-pay-button-white-with-text {
      -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line-with-text {
      -apple-pay-button-style: white-outline;
  }
}


@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
      --apple-pay-scale: 1; /* (height / 32) */
      display: inline-flex;
      justify-content: center;
      font-size: 12px;
      border-radius: 5px;
      padding: 0px;
      box-sizing: border-box;
      min-width: 200px;
      min-height: 32px;
      max-height: 64px;
  }
  .apple-pay-button-black-with-text {
      background-color: black;
      color: white;
  }
  .apple-pay-button-white-with-text {
      background-color: white;
      color: black;
  }
  .apple-pay-button-white-with-line-with-text {
      background-color: white;
      color: black;
      border: .5px solid black;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
  }
  .apple-pay-button-with-text > .text {
      font-family: -apple-system;
      font-size: calc(1em * var(--apple-pay-scale));
      font-weight: 300;
      align-self: center;
      margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
      width: calc(35px * var(--scale));
      height: 100%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      margin-left: calc(2px * var(--apple-pay-scale));
      border: none;
  }
}

.start-tiles {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(xl);
  justify-content: center;
  
  > div, > button {
    flex: 0 0 auto;
    min-width: 480px;
    background-color: white !important;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    border-radius: var(--w_tileBorderRadius);
    padding: spacer(xxl) spacer(xxl) spacer(xxl) spacer(xxl);
    max-width: 480px;
    box-sizing: border-box;
    @media screen and (max-width: $x-breakpoint-xs) {
      min-width: 100%;
      max-width: 100%;
      padding: spacer(xl) spacer(xl) spacer(xl) spacer(xl);
    }
  }
  
}

button.start-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: spacer(xxl) spacer(xxl) spacer(xxl) spacer(xxl);
  color: var(--w_primaryColor);
  svg {
    color: var(--w_primaryColor);
    width: 72px;
    height: 72px;
  }
  h2 {
    font-size: 2rem;
    font-weight: 700;
    padding-top: spacer(xl);
    padding-bottom: spacer(xl);
  }
  span {
    text-align: center;
    max-width: 400px;
  }
}

.start-popup {
  padding-left: spacer(xl);
  padding-right: spacer(xl);
}

.paypal-button {
  max-width: 100%;
  width: 240px;
  height: 44px;
  border-radius: var(--w_buttonBorderRadius);
  overflow: hidden;
}

.q {
  &-title {
    display: block;
    font-size: 2rem;
    text-align: left;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1.7rem;
    }
  }
  &-subtitle {
    display: block;
    padding-top: spacer(base);
    font-size: 1rem;
    text-align: left;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    section {
      margin-bottom: 0;
    }
  }
  &-row {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    text-align: left;
  }
  &-text {
    flex: 0 0 auto;
    font-weight: 700;
    font-size: 1.2rem;
  }
  &-options {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    flex-wrap: wrap;
    &-item {
      flex: 0 0 auto;
      background-color: #EEEEEE;
      border-radius: var(--w_buttonBorderRadius);
      border: 1px solid transparent;
      padding: 8px 16px;
      position: relative;
      input {
        display: block;
        opacity: 0;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
      }
    }
    &-selected {
      // background-color: var(--w_primaryColor);
      // color: var(--w_primaryText);
      border: 1px solid var(--w_primaryColor);
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      color: var(--w_primaryColor);
    }
  }
}

.refunded {
  display: inline-block;
  background-color: var(--w_colorRed);
  color: white;
  border-radius: var(--w_fieldBorderRadius);
  font-size: 1rem;
  font-weight: 700;
  padding: spacer(sm) spacer(base);
}

.pt {
  &-klarna {
    img {
      height: 32px;
    }
  }
  &-afterpay_clearpay {
    img {
      height: 44px;
    }
  }
  &-affirm {
    svg {
      width: 88px;
      height: 36px;
    }
  }
  &-paypal {
    img {
      height: 32px;
    }
  }
  &-visa {
    img {
      height: 24px;
    }
  }
  &-mastercard {
    img {
      height: 24px;
    }
  }
  &-amex {
    img {
      height: 24px;
    }
  }
}
@import '@/components/variables.scss';


.cp {

  position: fixed;
  right: spacer(base);
  top: calc(100vh - $x-header-height);
  bottom: spacer(base);
  background-color: white;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  top: calc($x-header-height + spacer(xl));
  right: spacer(xl);
  bottom: spacer(xl);
  width: calc($x-chat-max-width - spacer(xl));
  border-radius: var(--w_tileBorderRadius);
  transition: all 0.5s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $x-breakpoint-lg) {
    width: calc(100vw - spacer(xl) - spacer(xl));
  }

  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  &-button {
    position: fixed;
    bottom: spacer(base);
    right: spacer(base);
    display: none;
    width: $x-collapsed-width;
    height: $x-collapsed-width;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--w_secondaryColor);
    color: var(--w_secondaryText);
    border-radius: 100%;
    @media screen and (max-width: $x-breakpoint-xs) {
      margin-bottom: env(--safe-area-inset-bottom);
    }
    svg {
      flex: 0 0 auto;
      width: calc($x-collapsed-width / 2.5);
      height: calc($x-collapsed-width / 2.5);
    }
  }

  &-main {
    flex: 1 1 auto;
    overflow: hidden;
  }

  input, select {
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1rem !important;
    }
  }

  &-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    height: 44px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    align-items: center;
    div {
      flex: 0 0 auto;
      &:first-child {
        padding-left: spacer(base);
      }
    }
    span {
      flex: 1 1 auto;
    }
    &-close {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
    @media screen and (min-width: $x-breakpoint-lg) {
      display: none;
    }
    &-visible {
      display: flex;
    }
  }

  &-footer {
    flex: 0 0 auto;
    padding-bottom: spacer(xl);
    padding-left : spacer(xl);
    padding-right : spacer(xl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-top: spacer(base);
      padding-bottom: spacer(lg);
      padding-left : spacer(lg);
      padding-right : spacer(lg);
    }
  }

  &-collapsed {
    right: spacer(base);
    top: calc(100vh - $x-header-height);
    bottom: spacer(base);
    width: $x-collapsed-width;
    height: $x-collapsed-width;
    background: transparent;
    border-radius: var(--w_tileBorderRadius);
    box-shadow: none;
    @media screen and (max-width: $x-breakpoint-lg) {
      left: calc(100vw - $x-collapsed-width - spacer(base));
    }
    .cp-button {
      display: flex;
    }
    .cp-main, .cp-header, .cp-footer {
      display: none;
    }
  }

  &-view {
    padding: spacer(xl) spacer(xl) spacer(sm) spacer(xl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding: spacer(lg) spacer(lg) spacer(sm) spacer(lg);
    }
  }

}

.chat-panel {

  display: flex;
  flex-direction: column;
  position: fixed;
  right: spacer(base);
  top: calc(100vh - $x-header-height);
  bottom: spacer(base);
  width: -$x-collapsed-width;
  border-radius: var(--w_tileBorderRadius);

  &-open {
    @media screen and (max-width: $x-breakpoint-lg) {
      right: spacer(base);
      top: calc(100vh - $x-header-height);
      bottom: spacer(base);
      width: -$x-collapsed-width;
    }
    &-expanded {
      background-color: white;
      box-shadow: 0 0 20px rgba(0,0,0,0.05);
      top: calc($x-header-height + spacer(xl));
      right: spacer(xl);
      bottom: spacer(xl);
      width: calc($x-chat-max-width - spacer(xl));
      padding: spacer(xl);
    }
  }

  &-main {
    flex: 1 1 auto;
    overflow: hidden;
  }

  &-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    height: 44px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    align-items: center;
    div {
      flex: 0 0 auto;
      &:first-child {
        padding-left: spacer(base);
      }
    }
    span {
      flex: 1 1 auto;
    }
    &-close {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    @media screen and (min-width: $x-breakpoint-lg) {
      display: none;
    }
  }

  &-footer {
    flex: 0 0 auto;
    padding-top: spacer(base);
    // padding-left: spacer(xl);
    // padding-right: spacer(xl);
  }

  &-body {
    flex: 1 1 auto;
    padding: spacer(xl) spacer(xl) 0 spacer(xl);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &-button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: $x-collapsed-width;
    height: $x-collapsed-width;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--w_secondaryColor);
    color: var(--w_secondaryText);
    border-radius: 100%;
    svg {
      flex: 0 0 auto;
      width: calc($x-collapsed-width / 2.5);
      height: calc($x-collapsed-width / 2.5);
    }
    &-expanded {
      display: none;
    }
  }

}

.chat-message-bar {
  display: flex;
  flex-direction: row;
  gap: spacer(base);
  border: 1px solid #DDDDDD;
  border-radius: var(--w_fieldBorderRadius);
  // box-shadow: 0 0 20px rgba(0,0,0,0.1);

}

.chat-bubble {
  background-color: #EEEEEE !important;
  color: #000000 !important;
  border-radius: var(--w_tileBorderRadius) var(--w_tileBorderRadius) 12px 0 !important;
  * {
    color: #000000 !important;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    max-width: 90% !important;
  }
  &-buttons {
    padding-top: spacer(lg);
    display: flex;
    flex-direction: column;
    gap: spacer(sm);
  }
  > div {
    flex: 0 0 auto;
  }
  .button {
    height: auto;
    border: var(--w_chatBubbleButtonBorderWidth) solid var(--w_chatBubbleButtonBorderColor) !important;
    background-color: rgba(0,0,0,0.1) !important;
    color: #000000 !important;
    text-transform: none !important;
    transition: all 0.2s ease;
    padding: 10px 12px;
    box-sizing: border-box;
    min-height: 32px !important;
    line-height: 1rem;
    @media screen and (max-width: $x-breakpoint-xs) {
      height: auto !important;
      min-height: 32px !important;
    }
    &:active {
      background-color: rgba(0,0,0,0.05) !important;
    }
  }

  input, select, textarea {
    height: 36px !important;
    font-size: 14px !important;
    border-radius: var(--w_fieldBorderRadius);
    padding-left: spacer(md);
    padding-right: spacer(md);
    border: 0;
    color: black !important;
    background-color: white !important;
    @media screen and (max-width: $x-breakpoint-xs) {
      height: 44px !important;
      font-size: 1rem !important;
    }
  }

  section {
    display: block;
    margin-bottom: spacer(base);
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-info {
    display: block;
    padding: spacer(lg);
  }

  &-rows {
    display: block;
  }
  &-row {
    display: block;
  }
  &-price {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    padding-top: 8px;
    color: #09962f !important;
    &-orig {
      font-size: 1rem;
      font-weight: 400;
      text-decoration: line-through;
      color: #AA0000 !important;
    }
  }
  &-comment {
    display: block;
    padding-top: 8px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &-hero {
    display: block;
    margin-bottom: 8px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-iframe {
    display: block;
    width: 100%;
    padding: 35%;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    iframe {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &-desc {
    display: block;
    padding-top: 8px;
  }
  &-bullets {
    display: block;
    padding-top: 8px;
    .chat-bubble-row {
      display: flex;
      flex-direction: row;
      gap: spacer(base);
      * {
        font-size: 14px;
      }
      svg {
        height: 10px;
        width: 10px;
      }
    }
  }

  &-pill {
    display: inline-block;
    background-color: var(--w_primaryColor);
    color: var(--w_primaryText) !important;
    padding: 4px 6px;
    font-size: 12px !important;
    border-radius: var(--w_fieldBorderRadius);
  }

}

.chat-messages {
  
  display: flex;
  flex-direction: column;
  gap: spacer(base);

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-my {
    justify-content: flex-end;
    .chat-bubble {
      background-color: var(--w_primaryColor) !important;
      color: var(--w_primaryText) !important;
      border-radius: var(--w_tileBorderRadius) var(--w_tileBorderRadius) 0 12px !important;
      * {
        color: var(--w_primaryText) !important;
      }
      .button {
        background-color: rgba(255,255,255,0.2) !important;
        color: #FFFFFF !important;
      }
    }
  }

}

.g-chat-message-bar {
  border: 1px solid #DDDDDD;
  border-radius: var(--w_fieldBorderRadius);
  input {
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1rem !important;
    }
  }
}
